import intlTelInput from "intl-tel-input";

function updatePhoneInput(inputPhone,inputHidden, iti) {
    let newValue = inputPhone.value.replace(/[^0-9]/g, "");
    let countryData = iti.getSelectedCountryData();

    // remove dial code if it's the same as the country dial code
    if(newValue.substring(0,2) === countryData.dialCode){
        newValue = newValue.substring(2);
    }

    // remove leading zero if it's the same as the country dial code
    if (countryData.iso2 === "de") {
        if(parseInt(newValue.charAt(0)) === 0) {
            newValue = newValue.substring(1);
        }
    }
    inputPhone.value = newValue;

    if (inputHidden) {
        if(newValue !== ""){
            inputHidden.value = '+' + countryData.dialCode + newValue;
        }else{
            inputHidden.value = '';
        }
    }
}

export function updatePhoneInputByHidden(inputHidden){
    let itiGroup = inputHidden.previousElementSibling;
    if(itiGroup){
        if(itiGroup.classList.contains("iti")){
            let inputPhone = itiGroup.querySelector("input");
            inputPhone.value = inputHidden.value;
            updatePhoneInput(inputPhone,inputHidden,window.intlTelInputGlobals.getInstance(inputPhone));
        }
    }
}

export function initPhoneCountryFields(query = document.querySelectorAll("input[type=tel]")) {
    const utilsPath = "/plugins/utils/js/utils.js";
    let inputPhones = query;
    if (inputPhones) {
        for (let inputPhone of inputPhones) {
            let countryCode = inputPhone.dataset.countrycode;
            let inputValue = inputPhone.value;
            let inputHidden = inputPhone.nextElementSibling;

            if (countryCode === undefined) {
                countryCode = "DE";
            }

            const iti = intlTelInput(inputPhone, {
                utilsScript: utilsPath + "?" + inputValue,
                initialCountry: countryCode,
                separateDialCode: true,
                preferredCountries: GLOBALS.preferredPhoneCountries
            });

            if(inputPhone.closest(".infieldInputGroup")){
                inputPhone.closest(".infieldInputGroup").classList.add("is-filled");
            }

            inputHidden.classList.add("isIti");

            iti.promise.then(function () {
                updatePhoneInput(inputPhone,inputHidden, iti);
            });

            inputPhone.addEventListener("blur", function () {
                updatePhoneInput(inputPhone,inputHidden, iti);
            });

            inputPhone.addEventListener("countrychange", function () {
                updatePhoneInput(inputPhone,inputHidden, iti);
            });
        }
    }
}