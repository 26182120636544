import {focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {excludeModal, triggerModal} from "../../../default/global/js/modal";
import {initJumpmarks} from "../../../default/global/js/jumpmark";
import {initPhoneCountryFields} from "../../../default/global/js/phoneCountryField";
import {initInputMaskDate} from "../../../default/global/js/inputMaskDate";

window.addEventListener("load", (event) => {
    focusInputGroups();
    triggerModal();
    excludeModal();
    initJumpmarks();
    initPhoneCountryFields();
    initInputMaskDate();
});
